:root {
    --textColor: #252B21;
}

.success {
    font-family: var(--jakarta), sans-serif;
}

.success_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 16px;
    max-width: 1120px;
    margin: 0 auto;
}

.success h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 800;
}

.success p {
    font-size: 14px;
    margin: 0;
}

.success img {
    width: 100%;
}

.success_banner {
    height: 150px;
    display: flex;
    background-color: #0C1325;
}

.success_banner img {
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}

/* intro */

.success_intro {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.success_intro div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* goals */

.success_goals, .success_goals-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.success_goal {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
    border-radius: 10px;
}

.success_goal-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.success_goal p {
    color: var(--textColor);
    opacity: 0.6;
    font-weight: 500;
    line-height: 24px;
}

.success_goal h3 {
    line-height: 32px;
}

.success_goal span {
    display: flex;
    justify-content: end;
    color: var(--textColor);
    font-weight: 800;
    font-size: 60px;
}

/* Creators */

.success_creators {
    display: flex;
    flex-direction: column;
    line-height: 24px;
    gap: 16px;
}

.success_creators-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.success_creators_container {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
}

.success_creators-heading-desktop {
    display: none;
}

.success_creators-heading-mobile {
    display: block;
}

/* logotypes */

.success_logotypes {
    max-width: 896px;
    margin: 0 auto;
}

/* relations */
.success_relations {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 40px;
}

.success_relations section {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
}

.success_relations {
    color: var(--textColor);
    line-height: 24px;
}

.success_relations section h6 {
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 4px 0;
}

.success_relations section h4 {
    font-size: 16px;
    font-weight: 800;
    margin: 0 0 16px 0;
}

.success_relations section p {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.success_relations .ReactGridGallery {
    grid-template-columns: 175px;
    grid-template-rows: 175px;
}

.success_relations .ReactGridGallery_tile, .success_relations .ReactGridGallery_tile-viewport {
    width: 175px !important;
    height: 175px !important;
    border-radius: 10px;
}

@media (min-width: 380px) {
    .success_relations .ReactGridGallery {
        grid-template-columns: 175px 175px;
        grid-template-rows: 175px 175px;
    }
}
@media (min-width: 600px) {
    .success_relations .ReactGridGallery {
        grid-template-columns: 175px 175px 175px;
        grid-template-rows: 175px 175px 175px;
    }
}
@media (min-width: 768px) {
    .success_container {
        gap: 48px;
        margin-top: 32px;
        padding: 0 24px;
    }

    .success_banner {
        height: auto;
    }

    .success h3 {
        font-size: 20px;
    }

    /* intro */
    .success_intro {
        margin-top: 15px;
        flex-direction: row;
        gap: 15px;
    }

    .success_intro-text {
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
    }

    /* goals */
    .success_goals-container {
        flex-direction: row;
        margin: 0 auto;
    }

    .success_goal {
        flex: 1;
        max-width: 360px;
    }

    /* Creators */
    .success_creators_container {
        flex-direction: row;
    }

    .success_creators-text {
        justify-content: end;
        margin-bottom: 64px;
    }

    .success_creators-heading-desktop {
        display: block;
    }

    .success_creators-heading-mobile {
        display: none;
    }

    /* relations */
    .success_relations section h6 {
        font-size: 16px;
    }

    .success_relations section h4 {
        font-size: 18px;
    }

    .success_relations section p {
        font-size: 16px;
    }
}

@media (min-width: 1080px) {
    .success_container {
        gap: 96px;
    }

    .success h3 {
        font-size: 24px;
    }

    .success p {
        font-size: 16px;
    }


    /* goals */
    .success_goals {
        gap: 24px;
    }

    .success_goal {
        min-height: 560px;
        padding: 36px 40px;
    }

    .success_goal span {
        font-size: 96px;
    }

    /* creators */
    .success_creators-text {
        gap: 24px;
    }

    /* relations */
    .success_relations section {
        flex-direction: row;
        align-items: center;
        gap: 40px;
    }
    .success_relations section:nth-of-type(odd) {
        flex-direction: row-reverse;
    }
}
