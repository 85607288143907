/* GLOBAL */

:root {
	--roboto: 'Roboto Condensed', sans-serif;
	--jakarta: 'Plus Jakarta Sans', sans-serif;
	--the-font-bold: 'the bold font', sans-serif;
	--acrylic-hand-thick: 'Acrylic Hand Thick', sans-serif;
	--green: #5d9443;
	--light-gray: #efefef;
	--header-gray: #384445;
	--text-gray: #707070;
	--science-bg: #1d0b2c;
	--science-gray: #f6f6f6;
	--science-physics: #471372;
	--science-physics-sec: #ede8f1;
	--science-maths: #0073bd;
	--science-maths-sec: rgba(0, 115, 189, 0.1);
	--science-programming: #0e8c8d;
	--science-programming-sec: rgba(14, 140, 141, 0.1);
}

html {
	box-sizing: border-box;
	font-family: var(--roboto);
	-webkit-font-family: var(--roboto);
	-moz-font-family: var(--roboto);
}

*,
*::before,
*::after {
	box-sizing: inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

body {
	margin: 0;
}

bold {
	font-weight: bold;
}

.header {
	font-size: 33px;
	font-weight: normal;
	margin: 0;
}

.br {
	display: none;
}

.br-reverse {
	display: block;
}

button:focus {
	outline: none;
}

#root {
	overflow: hidden;
}
.buttonWithArrow {
	color: white;
	text-decoration: none;
	padding: 8px;
	font-size: 14px;
	border-radius: 8px;
	width: fit-content;
	display: flex;
	gap: 8px;
	position: relative;
}

.buttonWithArrow::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	transition: background-color 0.3s;
	z-index: 0;
	border-radius: 8px;
}

.buttonWithArrow:hover::before {
	background-color: #5C9443;
}

.buttonWithArrow img,
.buttonWithArrow span {
	position: relative;
	z-index: 1;
}

.buttonWithArrow img {
	width: auto !important;
	transition: padding-left 0.3s;
	position: relative;
	z-index: 1;
}

.buttonWithArrow:hover img {
	padding-left: 5px;
}

.image-big {
	max-width: 560px;
}

.image-medium {
	max-width: 400px;
}

.cookies {
	display: flex;
	align-items: center;
	max-width: 800px;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	padding: 10px;
	background: var(--green);
	color: var(--light-gray);
	font-size: 12px;
	text-align: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	z-index: 9;
}

.cookies p {
	margin: 0;
}

.cookies button {
	margin: 0 0 0 5px;
	border: none;
	background: none;
	color: var(--light-gray);
	font-size: 15px;
	cursor: pointer;
	transition: 0.1s;
}

.cookies button:hover {
	color: var(--header-gray);
}

/* NAVBAR */

.main-logo {
	position: absolute;
	top: -5px;
	left: 5px;
	width: 135px;
}

nav {
	position: fixed;
	z-index: 10;
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: 0.5s;
}

/* Navbar - hamburger icon */
.hamburger {
	padding: 10px;
	display: inline-block;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	margin: 0 21px 0px 0;
}

.hamburger__box {
	width: 35px;
	height: 35px;
	display: inline-block;
	position: relative;
}

.hamburger__inner,
.hamburger__blackInner {
	width: 100%;
	height: 4px;
	position: absolute;
	left: 0;
	top: 50%;
	border-radius: 5px;
}

.hamburger__inner {
	background: white;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 1);
}

.hamburger__blackInner {
	background: black;
}

.hamburger__inner::before,
.hamburger__inner::after {
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 1);
}

.hamburger__inner::before,
.hamburger__inner::after,
.hamburger__blackInner::before,
.hamburger__blackInner::after {
	width: 100%;
	height: 4px;
	background-color: inherit;
	position: absolute;
	content: '';
	left: 0;
	border-radius: 5px;
}

.hamburger__inner::before,
.hamburger__blackInner::before {
	top: -13px;
}

.hamburger__inner::after,
.hamburger__blackInner::after {
	top: 13px;
}

/* Navbar - mobile menu*/
.mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	background: white;
	transition: 0.5s;
	transform: translateX(-100vw);
}

/* Navbar - mobile menu - cross icon */
.mobile-menu__cross {
	width: 35px;
	height: 35px;
	display: inline-block;
	position: absolute;
	top: 50px;
	right: 35px;
	cursor: pointer;
}

.mobile-menu__cross__line {
	position: absolute;
	width: 100%;
	height: 5px;
}

.mobile-menu__cross__line::before,
.mobile-menu__cross__line::after {
	position: absolute;
	width: 100%;
	height: 4px;
	border-radius: 7px;
	background-color: black;
	content: '';
	left: 0;
}

.mobile-menu__cross__line::before {
	top: 10px;
	transform: translateY(-10px) rotate(-45deg);
}

.mobile-menu__cross__line::after {
	top: -10px;
	transform: translateY(10px) rotate(45deg);
}

/* Navbar - mobile menu logo and links */

.mobile-menu__logo {
	position: absolute;
	left: 10px;
	top: 10px;
	width: 150px;
}

.mobile-menu__links {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 50px 0 0 0;
}

.mobile-menu__links a {
	text-align: justify;
	text-decoration: none;
	font-size: 22px;
	margin: 15px 0;
	transition: color 0.5s;
	color: var(--header-gray);
}

.mobile-menu__links a:hover {
	color: var(--green);
}

/* main */
.main_container {
	padding-bottom: 200px;
	margin-bottom: -200px;
	position: relative;
}

.main_container-overlay{
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: #223B2E;
}

.main_container-overlay img{
	width: 250%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.main-text {
	color: #EBF3E6;
	text-align: center;
	margin: 0 auto 48px auto;
	line-height: 24px;
	position: relative;
}

.main-text h1 {
	font-family: var(--acrylic-hand-thick);
	font-weight: 400;
	font-size: 28px;
	margin: 0 0 16px 0;
	text-shadow: 0 4px 8px rgba(0, 0, 0, 0.18), 0 15px 15px rgba(0, 0, 0, 0.15), 0 34px 21px rgba(0, 0, 0, 0.09), 0 61px 24px rgba(0, 0, 0, 0.03), 0 95px 27px rgba(0, 0, 0, 0);
}

.main-text h4 {
	font-family: var(--jakarta);
	font-weight: 500;
	margin: 0 auto 40px auto;
	font-size: 14px;
	padding: 0 24px;
}

.main-text span {
	font-weight: 800;
}

.arrowDown {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #7D9D68;
	width: 50px;
	height: 50px;
	margin: 0 auto;
	border-radius: 50%;
	cursor: pointer;
	transition: color 0.3s ease-in-out;
	animation: pointDown 1s infinite ease-in-out alternate forwards;
}

.arrowDown:hover {
	background: #5F784F;
	transform: scale(1.16)
}

.arrowDown a {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes pointDown {
	from{
		transform: translateY(0);
	}
	to{
		transform: translateY(8px);

	}
}

.main-text-gif {
	display: none;
}

.main {
	padding-top: 40vh;
}

.main section{
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	position: relative;
}

.main .main_tile:first-of-type {
	grid-column: 1/3;
}

.main_tile {
	display: flex;
	font-family: var(--jakarta);
	min-height: 200px;
	background-color: white;
	transition: transform 0.3s;
	margin: 0 16px;
	border-radius: 10px;
}

.main_tile:hover {
	transform: translateY(-8px);
}

.main_tile:hover .main_tile-img_container {
	filter: brightness(120%);
}


.main_tile:hover .main_tile-img_container img {
	transform: translate(0, 0);
}


.main_tile-text {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.main_tile-content {
	display: flex;
	flex-direction: column;
	padding: 16px;
	justify-content: space-between;
}

.main_tile h2 {
	margin: 0 0 0 0;
	font-size: 20px;
}

.main_tile:first-of-type h2 {
	font-size: 26px;
}

.main_tile p {
	margin: 0;
	color: #252b21;
	font-weight: 500;
	opacity: 0.6;
	font-size: 14px;
	line-height: 24px;
}

.main_tile-img_container {
	display: flex;
	border-radius: 10px 0 0 10px;
	transition: filter 0.3s;
}

.main_tile-img_container div {
	display: flex;
	align-items: center;
}

.main_tile img {
	max-width: 60px;
}

.main_tile .main_tile-img_container img {
	position: relative;
	transform: translate(-5px, 5px);
	transition: 0.3s transform;
}

/* hero */

.hero {
	position: absolute;
	width: 100%;
	height: 470px;
	max-height: 1080px;
	top: 0;
	z-index: -1;
	overflow: hidden;
}

.hero-text {
	padding: 250px 0 70px 20px;
}

.hero-header {
	margin: 0;
	color: white;
	font-size: 23px;
	text-shadow: 0px 1px 1px #000;
	text-transform: uppercase;
}

.hero-p {
	margin: 0;
	color: white;
	font-size: 13px;
	font-weight: normal;
	max-width: 290px;
	padding: 15px 0 0 0;
	text-shadow: 0px 1px 1px #000;
}

.check-offer {
	display: none;
}

.about--header {
	margin: 35px 0 0 20px;
	color: var(--header-gray);
	font-size: 19px;
	font-weight: bold;
}

.about--text {
	font-size: 15px;
	padding: 0 20px;
	color: var(--text-gray);
	line-height: 1.5;
}

.about--text a {
	color: var(--text-gray);
}

.frame {
	width: 100%;
	min-height: 190px;
	padding: 0 20px;
}

.frame-colony {
	margin: 20px 0 0 0;
}

@media (min-width: 480px) {
	.frame {
		min-height: 250px;
	}
}

@media (min-width: 640px) {
	.frame {
		min-height: 286px;
	}
}

@media (min-width: 860px) {
	.frame {
		margin-top: 23px;
	}
}

/* Science */
.hero-science,
.hero-science-links {
	display: none !important;
}

.hero-science-mobile {
	display: flex;
	width: 100%;
	background: var(--science-bg);
	flex-direction: column;
	align-items: center;
	padding: 70px 0;
	margin: 0 0 -65px 0;
}

.hero-science-mobile a {
	width: 292px;
	text-decoration: none;
	margin: 30px 0 0 0;
}

.science-link {
	color: white;
	font-size: 27px;
	text-align: center;
	padding: 20px 0;
	margin: 0;
	border-radius: 10px 10px 0 0;
}

.link-physics {
	background: var(--science-physics);
}

.link-maths {
	background: var(--science-maths);
}

.link-programming {
	background: var(--science-programming);
}

.hero-science-mobile ul {
	color: black;
	background: var(--light-gray);
	margin: 0;
	padding: 20px 5px 20px 30px;
	list-style-type: disc;
	border-radius: 0 0 10px 10px;
	line-height: 16px;
}

.hero-science-mobile li:first-of-type {
	margin: 0;
}

.hero-science-mobile li {
	margin: 20px 0 0 0;
	font-size: 15px;
}

.back-to-main {
	position: absolute;
	color: white;
	left: 20px;
	top: 20px;
	text-decoration: none;
	font-size: 12px;
	z-index: 1;
	font-weight: 700;
}

.back-to-main--white {
	color: white !important;
}

.back-to-main--dark {
	color: var(--header-gray) !important;
}

.hero-science--header {
	margin: 0;
	color: white;
	font-weight: 700;
	text-align: right;
	padding: 0 20px 0 0;
	font-size: 42px;
	font-variant: small-caps;
	text-transform: lowercase;
	text-shadow: 0px 1px 1px #000;
}

.text-pasco,
.text-forMinors {
	max-width: 1080px;
	margin: 0 auto !important;
	position: relative;
	top: -135px;
}

.text-pasco .hero-science--header,
.text-forMinors .hero-science--header {
	position: relative;
	top: -16px;
}

.hero-science--p {
	margin: 0;
	padding: 0 20px 0 0;
	text-align: right;
	color: white;
	font-size: 13px;
	font-weight: normal;
	text-shadow: 0 1px 1px #000;
}

.program-desktop {
	display: none;
}

.program-section {
	width: 100%;
	padding: 0 20px 8px 20px;
	margin: 0 auto;
}

.science-background {
	position: relative;
	width: 100%;
	height: 262px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.science-background h2 {
	font-size: 35px;
	color: white;
	font-family: var(--the-font-bold);
	text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
}

.science-background a,
.back-to-main {
	position: absolute;
	top: 20px;
	left: 20px;
	font-size: 13px;
	color: white;
	text-decoration: none;
}

.science-content h3,
.science-register h3 {
	font-size: 20px;
	font-weight: 900;
	text-transform: uppercase;
	text-align: center;
	padding: 0 20px;
}

.science-text {
	padding: 0 20px 20px 20px;
	margin: 0;
	font-size: 17px;
	font-weight: 400;
	line-height: 22px;
}

.section img {
	width: 100%;
}

.section ul {
	font-size: 17px;
	padding: 0 20px 20px 20px;
	list-style-type: disc;
	line-height: 22px;
}

.section li {
	margin: 0 0 0 25px;
}

.science-maths .section ul {
	padding: 0;
}

.science-separator {
	height: 38px;
	width: 100%;
	margin: 20px 0 0 0;
}

.section-img {
	max-width: 580px;
	padding: 0 20px 20px 20px;
	margin: 0 auto;
}

.section-img img {
	border-radius: 5px;
}

.science-classes {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 20px;
	font-weight: bold;
}

.science-classes div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 224px;
	height: 75px;
	margin: 0 0 20px 0;
	border-radius: 5px;
}

.science-lecturers h3 {
	font-size: 20px;
	text-align: center;
	margin: 40px 0 20px 0;
}

.lecturers-gif img {
	width: 100%;
	margin: 0 0 10px 0;
}

.science-lecturers .science-text {
	text-align: center;
	margin: 0 0 15px 0;
}

.lecturer img {
	width: 100%;
}

.lecturer-img {
	min-width: 316px;
	min-height: 390px;
	position: relative;
}

.lecturer-img .photo,
.lecturer .gif {
	position: absolute;
}

.lecturers-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.lecturer-info {
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 30px 0;
	background: var(--science-gray);
	font-size: 15px;
	border-radius: 0 0 5px 5px;
}

.lecturer-info h4 {
	margin: 5px 0 0 0;
	padding: 0 0 30px 0;
	font-size: 27px;
}

.lecturer-more-info {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	bottom: 26px;
	width: 184px;
	margin: 0 auto;
	padding: 17px;
	border-radius: 5px;
	color: white;
	cursor: pointer;
	transition: 0.3s;
}

.lecturer-more-info img {
	width: 36px;
	height: auto;
	margin: 0 0 0 15px;
	transition: 0.3s;
}

.lecturer-more-info:hover {
	border-radius: 10px;
}

.lecturer-more-info:hover img {
	scale: 1.3 1;
	translate: 5px;
}

.science-price-list {
	display: grid;
	grid-template-columns: calc(100% - 99px) 99px;
	grid-gap: 10px;
	padding: 20px;
	justify-content: center;
	align-items: stretch;
}

.science-price-list h5,
.science-price-list p {
	background: var(--science-gray);
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 !important;
	border-radius: 5px;
	line-height: 24px;
}

.science-price-list h5 {
	font-size: 20px;
	text-transform: uppercase;
	padding: 8px 10px 8px 10px;
}

.science-price-list p {
	padding: 4px;
}

.lecturers-where {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120%;
	min-height: 203px;
	left: -10%;
	padding: 0 10% 10px 10%;
	background-size: cover !important;
	margin: 20px 0 60px 0;
}

.lecturers-where .science-text {
	padding: 30px 20px 0 20px;
}

.register-form {
	display: flex;
	position: relative;
	flex-direction: column;
	max-width: 1030px;
	padding: 0 15px;
	margin: 0 auto 40px auto;
}

.register-form label {
	font-size: 15px;
	position: relative;
	margin: 0 0 8px 0;
}

.register-form label:first-of-type {
	margin: 35px 0 8px 0;
}

.register-form input,
.register-form select,
.register-form textarea {
	height: 65px;
	margin: 0 0 20px 0;
	border: none;
	font-size: 16px;
	padding: 0 15px;
	background: var(--science-gray);
	font-family: var(--roboto);
}

.register-form select {
	cursor: pointer;
}

.register-form textarea {
	padding: 20px;
	height: 120px;
}

.register-form button,
.form-container button {
	height: 50px;
	width: 160px;
	color: white;
	cursor: pointer;
	border: none;
	border-radius: 5px;
	transition: 0.3s;
	font-family: var(--roboto);
}

.form-container li {
	margin: 10px 0 10px 15px;
}

.register-form button:focus {
	border: 2px black solid;
}

.register-form img {
	transition: 0.3s;
	margin: 0 0 0 10px;
}

.register-form button:hover {
	border-radius: 10px;
}

.register-form button:hover img {
	scale: 1.3 1;
	translate: 5px;
}

.register-button {
	display: flex;
	align-items: center;
}

.register-button button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.register-status {
	text-align: center;
	font-size: 19px;
	width: 100%;
	position: relative;
}

.register-status img {
	height: 60px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.register-form label::after,
.form-required::after {
	content: ' *';
	color: red;
}

.register-form label:last-of-type::after {
	content: '';
}

/* Science - subpages */
.science-background--physics {
	background: var(--science-physics);
}

.science-background--maths {
	background: var(--science-maths);
}

.science-background--programming {
	background: var(--science-programming);
}

.science-color-physics-bigger,
.science-color-physics {
	color: var(--science-physics);
	font-weight: bold;
}

.science-color-maths-bigger,
.science-color-maths {
	color: var(--science-maths);
	font-weight: bold;
}

.science-color-programming-bigger,
.science-color-programming {
	color: var(--science-programming);
	font-weight: bold;
}

.science-color-physics-bigger,
.science-color-maths-bigger,
.science-color-programming-bigger {
	font-size: 19px;
}

.science-classes.classes-physics {
	color: var(--science-physics);
}

.science-classes.classes-physics div {
	background: var(--science-physics-sec);
}

.science-classes.classes-maths {
	color: var(--science-maths);
}

.science-classes.classes-maths div {
	background: var(--science-maths-sec);
}

.science-classes.classes-programming {
	color: var(--science-programming);
}

.science-classes.classes-programming div {
	background: var(--science-programming-sec);
}

.physics-separator {
	background: var(--science-physics);
}

.maths-separator {
	background: var(--science-maths);
}

.programming-separator {
	background: var(--science-programming);
}

.lecturers-physics .lecturer-img .gif.gif-1 {
	top: 11%;
}

.lecturers-physics .lecturer-img .gif.gif-2 {
	bottom: 8%;
	right: 17%;
}

.lecturers-physics .lecturer-img .gif.gif-3 {
	top: 4%;
	left: 38%;
}

.lecturers-physics .lecturer-img .gif {
	width: 40%;
}

.lecturers-maths .gif {
	top: 5%;
	left: 12%;
	width: 57%;
}

.lecturers-programming .gif {
	width: 88%;
	left: 5%;
}

.more-info-physics {
	background: var(--science-physics);
}

.more-info-maths {
	background: var(--science-maths);
}

.more-info-programming {
	background: var(--science-programming);
}

.science-programming.section-1 ul {
	text-align: center;
	list-style-type: none;
}

.science-programming.section-1 li {
	margin: 20px 0 0 0;
}

.science-programming.section-2 li {
	margin: 0;
}

.science-physics.section-2 .section:nth-of-type(1) ul {
	padding: 0 20px 20px 20px;
}

/* For Minors */
.forMinors-social {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 30px 0;
	padding: 0 10px;
	text-align: center;
	color: var(--header-gray);
}

.social-continer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 15px;
}

.social-continer img {
	width: 65px;
	transition: transform 0.3s;
}

.social-continer img:hover {
	transform: scale(1.1);
}

.forMinors-tutors {
	max-width: 1080px;
	margin: 0 auto;
}

.forMinors-tutors ul {
	list-style-type: disc;
	padding: 0 0 0 30px;
}

.forMinors-logo {
	padding: 20px;
	max-width: 400px;
	margin: 25px auto;
}

.forMinors-logo img,
.pasco-logo img {
	width: 100%;
}

.about-forMinors {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
}

.about-forMinors .header {
	grid-row: 1;
}

.about--text--forMinors:nth-of-type(1) {
	grid-row: 2;
}

.about--text--forMinors:nth-of-type(2) {
	grid-row: 3;
}

.about--text--forMinors:nth-of-type(3) {
	grid-row: 4;
}

.about--text--forMinors:nth-of-type(4) {
	grid-row: 5;
}

.about--text--forMinors:nth-of-type(5) {
	grid-row: 6;
}

.forMinors-social {
	grid-row: 6;
	grid-column: 3/6;
}

.frame-forMinors:nth-of-type(1) {
	grid-row: 2/4;
}

.frame-forMinors:nth-of-type(2) {
	grid-row: 4/6;
	margin: 24px 0 0 0;
}

.frame-forMinors {
	grid-column: 3/6;
}

.about--text--forMinors {
	grid-column: 1/3;
}

.forMinors-slider {
	grid-column: 1/4;
	grid-row: 2;
	position: relative;
	max-width: 700px;
	overflow: hidden;
	margin: 25px 15px 10px 15px;
}

.pasco-slider {
	position: relative;
	max-width: 768px;
	overflow: hidden;
	margin: 25px 15px 10px 15px;
}

.pasco-tutors .about--text {
	padding: 0;
	text-align: justify;
}

.swiper {
	position: relative;
}

.slider-slide img,
.pasco-slider img {
	width: 100%;
	border-radius: 15px !important;
}

.swiper-nav {
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	z-index: 1;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
}

.nextNav,
.prevNav {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	z-index: 1;
}

.prevNav {
	transform: translateY(-50%) rotate(180deg);
	left: 10px;
}

.nextNav {
	right: 10px;
}

.nextNav img,
.prevNav img {
	width: 100%;
}

.swiper-pagination-bullet-active {
	background: white !important;
	border: none !important;
}

.swiper-pagination-bullet {
	width: 20px;
	height: 20px;
	opacity: 1;
	background: none;
	border: 3px solid white;
	margin: 0 15px 0 0;
}

.swiper-pagination-bullet:last-of-type {
	margin: 0;
}

/* Pasco */
.hero-pasco,
.hero-forMinors {
	position: relative !important;
	background-position: 28% center !important;
	filter: blur(1px);
}

.about-pasco ul {
	list-style-type: disc;
	padding: 0 0 0 30px;
}

.pasco--text {
	background: #286ca5;
	width: 90%;
	color: white;
	padding: 20px !important;
	margin: 15px auto;
	border-radius: 15px;
	max-width: 520px;
}

thin {
	font-weight: 300;
}

.pasco-logo {
	max-width: 220px;
	width: 80%;
	margin: 32px auto;
}

/* Footer */
.footer-social path {
	transition: 0.3s;
}

.footer-social--ig:hover path {
	fill: #e1306c;
}

.footer-social--fb:hover path {
	fill: #4267b2;
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	background: var(--light-gray);
	margin: 65px 0 0 0;
	padding: 40px 25px;
}

.footer-sections {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
}

.footer-section h2 {
	color: var(--header-gray);
	font-size: 17px;
	padding: 0 0 15px 0;
	min-width: 100px;
}

.footer-section p,
.footer-copyright p {
	color: var(--text-gray);
	font-size: 13px;
	margin: 15px 0;
}

.footer-copyright p {
	min-width: 90px;
	text-align: right;
	margin: 15px 0 15px -30px;
}

.footer-copyright a {
	text-decoration: none;
}

.footer-section p:nth-of-type(3),
.footer-section p:nth-of-type(5) {
	margin: 15px 0 -8px 0 !important;
}

.footer-section svg:first-of-type {
	margin: 0 8px 0 0;
}

.footer-section a {
	text-decoration: none;
	color: var(--text-gray);
}

/* Offer -section 1 and 5*/
.offer {
	margin: 150px 0 0 0;
}

.expanded__title {
	font-size: 22px;
	transition: 0.3s;
}

.active .title__border {
	border-top: 1px var(--green) solid;
	border-bottom: 1px var(--green) solid;
}

.title__border {
	border-top: none;
	border-bottom: 1px gray solid;
}

.offer__expanded {
	position: relative;
	max-width: 1050px;
	margin: 0 auto;
}

.expanded__text i {
	display: block;
	max-width: 90%;
	text-align: center;
	font-weight: bold;
	margin: 0 auto;
}

.expanded__text-quote {
	text-align: right;
	font-style: normal;
}

.expanded__cross {
	position: absolute;
	width: 20px;
	height: 20px;
	right: 24px;
	padding: 20px;
	top: 9px;
	cursor: pointer;
}

.expanded__cross::before,
.expanded__cross::after {
	content: '';
	width: 2px;
	height: 20px;
	background: white;
	position: absolute;
	top: 10px;
}

.active .expanded__cross::before,
.active .expanded__cross::after {
	background: var(--green);
}

.expanded__cross::after {
	transform: rotate(90deg);
}

.active .expanded__cross::before {
	display: none;
}

.expanded__title {
	padding: 15px 0 15px 35px;
	color: white;
	font-weight: bold;
	background: var(--green);
	cursor: pointer;
}

.active .expanded__title {
	border-bottom: 1px solid var(--green);
	border-top: 1px solid var(--green);
	color: var(--green);
	background: white;
}

.expanded__text {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-out;
	font-size: 20px;
	margin: 20px 35px;
	line-height: 1.4;
	text-align: justify;
}

.expanded__list li {
	padding: 0 0 0 15px;
	position: relative;
}

.expanded__list li::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-color: var(--green);
}

/* Offer - section 2 and 3*/
table {
	width: 100%;
	border-spacing: 0;
	max-width: 1120px;
	margin: 0 auto;
}

.offer__section2,
.offer__section3 {
	margin: 50px 0 0 0;
}

.term__header {
	background: var(--green);
	height: 70px;
	color: white;
	font-size: 20px;
	width: 145px;
	font-weight: bold;
}

.term__header:first-of-type {
	font-size: 23px;
}

.term__row,
.term__row-second {
	font-size: 18px;
	text-align: center;
}

.term__row {
	padding: 20px;
}

.term__row-second {
	padding: 0 20px 20px 20px;
}

/* Offer - section 4 */
.offer__section4 {
	margin: 60px 0 0 0;
}

.offer__title {
	font-size: 30px;
	font-weight: bold;
	margin: 0 0 20px 25px;
}

.offer__day-schedule {
	min-height: 200px;
	position: relative;
}

.day-schedule__line {
	width: 85%;
	max-width: 1050px;
	height: 3px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.line__upArrow,
.line__downArrow {
	position: absolute;
	width: 4px;
	height: 14px;
	background: var(--green);
	right: 1px;
	animation: unarrow 1s linear alternate forwards;
	opacity: 0;
}

.animation-arrow {
	animation: arrow 0.3s 0.7s linear alternate forwards;
}

@keyframes arrow {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes unarrow {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.line__downArrow {
	transform: rotate(45deg);
	top: -2px;
}

.line__upArrow {
	transform: rotate(-45deg);
	top: -9px;
}

.line {
	width: 0;
	height: 100%;
	position: absolute;
	transition: 0.5s;
	background: var(--green);
	animation: unline 1s linear alternate forwards;
}

.animation-line {
	animation: line 1s linear alternate forwards;
}

@keyframes line {
	from {
		padding: 0;
	}
	to {
		padding: 0 100% 0 0;
	}
}

@keyframes unline {
	from {
		padding: 0 100% 0 0;
	}
	to {
		padding: 0 0 0 0;
	}
}

.line__beginning {
	width: 14px;
	height: 14px;
	position: absolute;
	background: var(--green);
	border-radius: 50%;
	left: -5px;
	top: -5px;
}

.day-schedule__dot {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--green);
	position: absolute;
	transition: 0.3s transform;
	cursor: pointer;
}

.day-schedule__dot:hover {
	transform: scale(1.3);
}

.day-schedule__dot::before {
	content: '';
	width: 50%;
	height: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	border-radius: 50%;
}

.dot1,
.dot2,
.dot3,
.dot4,
.dot5,
.dot6,
.dot7,
.dot8 {
	bottom: 35px;
}

.dot1 {
	left: 11%;
}

.dot2 {
	left: 22%;
}

.dot3 {
	left: 33%;
}

.dot4 {
	left: 44%;
}

.dot5 {
	left: 55%;
}

.dot6 {
	left: 66%;
}

.dot7 {
	left: 77%;
}

.dot8 {
	left: 88%;
}

.dot9,
.dot10,
.dot11,
.dot12,
.dot13 {
	top: 35px;
}

.dot9 {
	left: 16%;
}

.dot10 {
	left: 32%;
}

.dot11 {
	left: 48%;
}

.dot12 {
	left: 64%;
}

.dot13 {
	left: 80%;
}

.dot__popUp {
	text-align: center;
	border: 1px var(--green) solid;
	padding: 10px;
	margin: 0 0 5px 0;
	background: white;
}

.offer__section5 {
	margin: 70px 0 0 0;
}

/* Offert - section 6 */
.offer__section6 {
	margin: 50px 0 0 0;
}

.offer__text {
	font-size: 20px;
	margin: 30px;
	text-align: justify;
	line-height: 1.4;
	max-width: 1050px;
}

.offer__text a {
	color: black;
	font-weight: bold;
	text-decoration: none;
	text-align: center;
}

/* Offert - gallery react-grid-gallery */
.ReactGridGallery {
	max-width: 1050px;
	margin: 0 auto;
	min-height: 100px;
	display: grid;
	justify-content: center;
	grid-gap: 10px;
}

@media (min-width: 260px) {
	.ReactGridGallery {
		grid-template-columns: 250px;
	}
}

@media (min-width: 540px) {
	.ReactGridGallery {
		grid-template-columns: 250px 250px;
	}
}

@media (min-width: 770px) {
	.ReactGridGallery {
		grid-template-columns: 250px 250px 250px;
		padding: 0 0 50px 0;
	}
}

.ReactGridGallery_tile,
.ReactGridGallery_tile-viewport {
	transition: 0.3s;
	width: 250px !important;
	height: 250px !important;
}

.ReactGridGallery_tile-viewport img {
	width: 100% !important;
	height: 100% !important;
	margin-left: 0 !important;
	margin-top: 0px;
}

.ReactGridGallery_tile:hover {
	filter: brightness(0.9);
}

/* downloads */
.downloads__main,
.form__main {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	padding: 0 20px;
}

.downloads__header,
.form__header {
	margin-top: 120px !important;
	margin-bottom: 35px !important;
}

.downloads__list a {
	color: black;
	cursor: pointer;
	text-decoration: none;
	transition: color 0.3s;
	margin-right: 40px;
}

.downloads__list a:hover {
	color: var(--green);
}

.downloads__list {
	list-style-type: disc;
	padding: 0 0 0 35px;
}

/* Form */
.form__info {
	line-height: 1.4;
}

.form-container {
	display: flex;
	flex-direction: column;
	line-height: 1.4;
}

.form-container ol {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.form-container input[type='radio'] {
	appearance: auto !important;
	height: auto;
	margin: 0;
	cursor: pointer;
}

.form-container input,
.form-container select,
.form-container textarea {
	height: 65px;
	margin: 0 0 20px 0;
	border: none;
	font-size: 16px;
	padding: 0 15px;
	background: var(--science-gray);
	font-family: var(--roboto);
	margin: 0 0 35px 0;
}

.form-container select {
	cursor: pointer;
}

.form-container textarea {
	padding: 20px;
	height: 120px;
}

.form-container label {
	font-size: 15px;
	position: relative;
	margin: 0 0 8px 0;
}

/* input type number arrows delete */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.form-radio {
	display: flex;
	align-items: center;
	font-size: 15px;
	margin: 10px 0 35px 0;
}

.radio-labels {
	margin: 0;
	display: grid;
	grid-template-rows: 1fr 1fr;
}

.radio-labels label {
	margin: 0;
}

.radio-labels div {
	display: flex;
	min-width: 140px;
	justify-content: space-around;
	text-align: center;
}

.radio-labels div * {
	min-width: 15px !important;
}

.form-checkbox {
	display: flex;
	align-items: center;
}

.form-container input[type='checkbox'] {
	appearance: auto;
	min-width: 20px;
	margin: 0;
	cursor: pointer;
}

.form-checkbox label {
	margin: 0 0 0 30px;
	max-width: 950px;
	cursor: pointer;
}

.form-container button {
	background: var(--green);
	margin: 0;
}

/* Faq */
.question {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.question h3 {
	margin: 15px 0 -10px 0;
}

.faq .question:last-of-type {
	padding: 0 0 20px 0;
}

/* Policy */
.policy {
	margin: 0 30px;
	line-height: 1.4;
	max-width: 968px;
}

.policy {
	padding: 0 0 30px 0;
}

.policy p,
.policy li,
.policy ol,
.policy ul {
	font-size: 16px;
	margin: 0;
}

.policy li {
	margin: 5px 0 5px 0;
}

.policy ol,
.policy ul {
	padding: 0;
}

.policy h3 {
	margin: 30px 0 10px;
	font-size: 20px;
}

.policy h2 {
	margin: 140px 0 10px 0;
	font-size: 20px;
}

/* Team */
.team__section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 0 30px 0;
}

.team__header,
.faq__header,
.policy__header {
	margin: 140px 0 0 30px;
}

.team__name {
	font-size: 25px;
	margin: 60px 0 20px 0;
}

.team__text,
.question {
	font-size: 20px;
	margin: 20px 30px 10px 30px;
	text-align: justify;
	line-height: 1.4;
}

.team__text a {
	color: black;
}

.team__imgs {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: center;
}

.team__img {
	width: 316px;
	height: 316px;
	margin: 0 0 15px 0;
}

.team__img img {
	width: 100%;
}

.team__imgs-no-text {
	display: flex;
    flex-direction: column;
	gap: 16px;
}

.team__img-no-text{
    max-width: 900px;
}

.team__img-no-text img {
    width: 100%;
}

/* Contact */
.contact {
	margin: 100px 0 0 0;
	min-height: 100vh;
}

.contact__text {
	margin: 20px 30px 0 30px;
}

.contact__p {
	margin: 20px 0;
	font-size: 20px;
	line-height: 1.4;
}

.contact__img {
	width: 100%;
}

.contact__icons {
	width: 150px;
	height: 60px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.contact__FB,
.contact__IG {
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s border-radius;
}

.contact__FB:hover,
.contact__IG:hover {
	border-radius: 10px;
}

.contact__FB img,
.contact__IG img {
	width: 70%;
}

.contact__FB {
	background: #3b5998;
}

.contact__IG {
	background: #c13584;
}

/* Recomendations */
.recomendations {
	margin: 100px 0 0 0;
}

.recomendations__header,
.downloads__header,
.form__header {
	margin: 30px 0 30px 30px;
}

.recomendations__section {
	width: 100%;
	padding: 70px 00px;
	font-size: 20px;
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.recomendations__section:nth-of-type(odd) {
	background: var(--green);
	color: white;
}

.recomendations__section:last-of-type {
	margin: 0 0 80px 0;
}

.section__text {
	margin: 0 20px;
}

.recomendations__img {
	width: 100%;
}

.section__text {
	margin: 0 20px;
}

.section__author {
	font-weight: bold;
}

.quote {
	position: absolute;
	font-size: 85px;
	bottom: -70px;
}

.quote:nth-of-type(odd) {
	right: 20px;
}

.quote:nth-of-type(even) {
	left: 20px;
}

.menu-desktop {
	display: none;
}

/* History */
.history__title {
	font-size: 33px;
	margin: 140px 0 15px 30px;
	font-weight: 300;
}

.element__year {
	font-size: 22px;
}

.history__element {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 0 70px 0;
}

.history__element:nth-of-type(odd) {
	background: var(--green);
	color: white;
}

.element__text {
	margin: 0 30px 30px 30px;
	text-align: justify;
	font-size: 18px;
	line-height: 1.4;
	max-width: 770px;
}

.element__images {
	display: flex;
}

.history__thanks {
	max-width: 770px;
	font-size: 18px;
	margin: 35px 30px;
	line-height: 1.4;
	text-align: justify;
}

.thanks__p {
	margin: 0;
}

ul {
	margin: 0;
	list-style: none;
	padding: 0;
}

li {
	margin: 10px 0;
}

.under-construction,
.page404 {
	width: 100vw;
	height: calc(100vh - 96px);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 40px;
	color: var(--green);
	padding: 30px;
}

/* media queries */
@media (min-width: 500px) {
	.main_container-overlay img {
		width: 150%;
		position: absolute;
		left: inherit;
		transform: initial;
	}
}
@media (min-width: 617px) {
	.main_tile img {
		max-width: 100px;
	}

	.lecturers-where {
		max-width: 617px;
		min-height: unset;
		margin: 20px auto 80px auto;
		left: unset;
		padding: 0 0 15px 0;
	}

	.lecturers-programming .lecturers-where {
		max-width: unset;
		left: -10%;
		min-height: 256px;
	}

}

@media (min-width: 776px) {
	.lecturers-programming .lecturers-where {
		max-width: 776px;
		min-height: unset;
		margin: 20px auto 30px auto;
		left: unset;
		padding: 0 0 15px 0;
	}
}

@media (min-width: 700px) {
	.forMinors-slider {
		margin: 25px auto 10px auto;
	}

	.main_container-overlay img {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.buttonWithArrow {
		padding: 12px 16px;
	}

	.main_container {
		margin: 0;
		padding-bottom: 50px;
	}

	.main-text h1 {
		font-size: 36px;
	}

	.main-text h4 {
		font-size: 16px;
	}

	.main section {
		max-width: 90%;
		margin: 0 auto;
		gap: 25px;
	}
	.main_tile {
		margin: 0;
	}

	.main_tile-img_container {
		border-radius: 10px 0 0 10px;
	}

	.main_tile h2 {
		font-size: 25px;
	}

	.main_tile:first-of-type h2 {
		font-size: 31px;
	}

	.main_tile-content {
		padding: 25px;
	}

	.br {
		display: block;
	}

	.about--text {
		text-align: justify;
	}

	.hero-colony {
		background-position: center center !important;
		height: 900px;
	}

	.back-to-main {
		font-size: 14px;
		z-index: 1;
		color: var(--header-gray);
	}

	.hero-science {
		display: block !important;
		height: 845px;
		max-height: none;
	}

	.hero-forMinors {
		height: 470px;
		background-position: center center !important;
	}

	.hero-science-links {
		display: block !important;
		height: 730px;
	}

	.hero-science-links h1 {
		color: white;
		font-size: 44px;
		text-align: center;
		margin: 115px 0 50px 0;
		text-shadow: 0 1px 2px #000;
	}

	.hero-science-links a {
		display: flex;
		max-width: 900px;
		text-decoration: none;
		margin: 0 auto 40px auto;
		padding: 0 15px;
		transition: 0.3s;
	}

	.hero-science-links a:hover {
		translate: 0 -7px;
	}

	.hero-science-links ul {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 620px;
		background: white;
		list-style-type: disc;
		gap: 20px;
		border-radius: 0 10px 10px 0;
		box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 1);
	}

	.hero-science-links li {
		color: black;
		margin: 0 0 0 40px;
	}

	.hero-science-mobile {
		display: none;
	}

	.science-link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 280px;
		height: 160px;
		border-radius: 10px 0 0 10px;
	}

	.hero-pasco {
		height: 470px;
		background-position: right 65% !important;
	}

	.hero-text {
		padding: 400px 20px 240px 20px;
	}

	.hero-header {
		font-size: 40px;
	}

	.hero-p {
		font-size: 20px;
		text-align: right;
		max-width: unset;
	}

	.check-offer {
		margin: 20px 0px 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 200px;
		color: white;
		padding: 15px;
		background: var(--green);
		border-radius: 10px;
		text-decoration: none;
		transition: 0.3s linear;
	}

	.check-offer--arrow {
		position: relative;
		margin: 0 0 0 10px;
		width: 30px;
		height: 2px;
		background: white;
		transition: 0.3s linear;
	}

	.check-offer--arrow::before,
	.check-offer--arrow::after {
		content: '';
		position: absolute;
		width: 10px;
		height: 2px;
		background: white;
		right: -2px;
	}

	.check-offer--arrow::before {
		top: 3px;
		transform: rotate(-45deg);
	}

	.check-offer--arrow::after {
		top: -3px;
		transform: rotate(45deg);
	}

	.check-offer:hover .check-offer--arrow {
		transform: translateX(5px);
	}

	.check-offer:hover {
		border-radius: 15px;
	}

	.about {
		margin-bottom: 30px;
	}

	.hero-science--header {
		font-size: 49px;
	}

	.hero-science--p {
		font-size: 16px;
	}

	.pasco-slider {
		margin: 25px auto 10px auto;
	}

	.swiper-pagination-bullet {
		width: 30px;
		height: 30px;
	}

	.science-classes {
		justify-content: space-between;
		flex-direction: row !important;
		max-width: 768px;

		margin: 50px auto 0 auto;
	}

	.form__main,
	.downloads__main {
		font-size: 18px;
	}

	.radio-labels {
		margin: 0 0 0 30px;
	}

	.form-container button {
		margin: 0 0 20px 0;
	}

	.footer {
		margin-top: 0;
	}

	.footer-section h2 {
		font-size: 21px;
	}

	.footer-section p {
		font-size: 17px;
	}

	.footer-sections {
		grid-column-gap: 95px;
		margin: 0 0 0 10%;
	}

	.policy {
		margin: 0 auto;
	}
}

@media (min-width: 1050px) {
	/* hero */
	.hero-text {
		padding: 400px 20px 310px 20px;
	}

	.about--text {
		width: 100%;
		margin: 20px auto 0 auto;
		font-size: 15px;
	}

	/* Downloads */
	.downloads__main {
		font-size: 30px;
	}

	/* Form */
	.form__main {
		font-size: 22px;
	}

	/* Offer */
	.offer__title {
		font-size: 36px;
		margin: 0 0 30px 20%;
	}

	.offer__text {
		margin: 30px auto;
	}

	/* Team */
	.team__section:nth-of-type(odd) {
		background: var(--green);
	}

	.team__section:nth-of-type(odd) .team__name,
	.team__section:nth-of-type(odd) .team__text {
		color: white;
	}

	.team__imgs {
		flex-direction: row;
	}

	.team__img {
		margin: 0 10px 0 0;
	}

	.team__img:last-of-type {
		margin: 0;
	}

	.team__header,
	.faq__header,
	.policy__header {
		margin: 150px 0 60px 90px;
	}

	.team__name {
		font-size: 40px;
		margin: 70px 0 20px 0;
	}

	.team__text {
		max-width: 968px;
	}

	/* Faq */
	.question {
		margin: 0 auto;
		max-width: 968px;
	}

	/* Contact */
	.contact {
		margin: 150px auto 0 auto;
		display: flex;
		flex-direction: row-reverse;
		max-width: 919px;
		min-height: initial;
	}

	.contact__img {
		width: 600px;
	}

	.contact__text {
		margin: 0 40px 0 0;
	}

	.contact__p {
		font-size: 23px;
	}

	.footer-bottom {
		position: absolute;
		bottom: 0;
		z-index: -1;
	}

	/* Recomendations */
	.recomendations__img {
		display: none;
	}

	.recomendations__header,
	.downloads__header,
	.form__header {
		margin: 160px 0 60px 130px;
	}

	.recomendations__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 1050px;
		margin: 0 auto;
	}

	.recomendations__section {
		max-width: 545px;
		background: var(--green) !important;
		color: white !important;
	}

	.recomendations__section:nth-of-type(odd) {
		margin: 0 575px 70px 0;
	}

	.recomendations__section:nth-of-type(even) {
		margin: 0 0 100px 575px;
	}

	.team__section:nth-of-type(odd) a {
		color: white;
	}

	.team__section:nth-of-type(even) a {
		color: black;
	}

	.section__text {
		font-size: 22px;
		margin: -20px 0 0 0;
		padding: 0 20px;
	}

	.quote {
		left: 0;
	}

	/* history */
	.history__title {
		font-size: 46px;
		margin: 150px 0 60px 90px;
		font-weight: 300;
	}

	.element__year {
		font-size: 26px;
	}

	.element__text {
		font-size: 21px;
	}

	.history__thanks {
		margin: 35px auto;
	}
}

@media (min-width: 1130px) {
	.about--text {
		padding: 0;
	}

	.about--header {
		margin: 35px 0 0 0;
	}

	.participation p {
		padding: 0;
	}

	.policy h3,
	.policy h2 {
		font-size: 22px;
	}

	.policy p,
	.policy ol,
	.policy ul,
	.policy li {
		font-size: 18px;
	}
}

@media (min-width: 1120px) {
	.menu-desktop {
		display: block;
		font-size: 20px;
		padding: 0 0 0 100px;
	}

	nav {
		justify-content: flex-end;
		height: 100px;
	}

	.hamburger,
	.mobile-menu {
		display: none;
	}

	.menu-ul {
		list-style: none;
		display: flex;
		margin: 0 30px 0 0;
	}

	.menu-link {
		color: var(--header-gray);
		text-decoration: none;
		margin: 0 20px 0 0;
		font-size: 18px;
		font-weight: 700;
		transition: 0.5s;
	}

	.menu-link-hero {
		color: black !important;
	}

	.menu-link:hover {
		color: var(--green) !important;
	}
}

@media (min-width: 1080px) {
	.main_container-overlay img {
		top: -5%;
	}
	.main-text h1 {
		font-size: 54px;
		margin: 0 0 32px 0;
	}

	.main-text-gif {
		display: block;
		position: absolute;
		bottom: -20px;
		left: 50%;
		transform: translateX(calc(-50% + 330px));
		width: 130px;
	}

	.main section {
		max-width: 80%;
		display: grid;
		margin: 0 auto;
		grid-template-columns: 1fr 1fr;
	}
	.main_tile {
		min-height: 240px;
	}

	.main_tile h2 {
		font-size: 30px;
	}

	.main_tile:first-of-type h2 {
		font-size: 36px;
	}

	.main_tile p {
		font-size: 16px;
		padding-bottom: 24px;
	}

	.main_tile-content {
		padding: 36px;
	}

	.br-reverse {
		display: none;
	}

	.hero-text {
		width: 1080px;
		display: grid;
		grid-template-columns: 565px 484px;
		justify-content: center;
		margin: 0 auto;
	}

	.hero-header {
		font-weight: 900;
		font-size: 35px;
		text-transform: uppercase;
		grid-column: 1;
	}

	.hero {
		z-index: -1;
	}

	.hero-science h1 {
		margin: 200px 0 50px 0;
	}

	.hero-p {
		font-size: 16px;
		text-align: left;
		padding: 0;
		grid-column: 2;
	}

	.hero-p:nth-of-type(2) {
		margin: -20px 0px 0 0;
	}

	.check-offer {
		grid-column: 2;
		margin: 30px 0 0 0;
	}

	.about {
		display: grid;
		grid-template-columns: 1fr 1fr;
		max-width: 1080px;
		margin: 0 auto 100px auto;
	}

	.about-forMinors {
		margin: 0 auto 50px auto !important;
	}

	.back-to-main {
		font-size: 16px;
	}

	.about-section:nth-of-type(2) .about--header {
		margin: 35px 0 0 30px;
	}

	.frame {
		padding: 0 0 0 30px;
		margin: 40px 0 0 0;
	}

	.frame-colony {
		margin: 23px 0 0 0;
	}

	.hero-science {
		height: 100vh;
	}

	.hero-science-links {
		height: calc(100vh - 115px);
	}

	.hero-pasco,
	.hero-forMinors {
		height: 600px;
	}

	.hero-science--header {
		font-size: 59px;
	}

	.about--header {
		grid-column: 1;
	}

	.program-mobile {
		display: none;
	}

	.program-desktop {
		display: block;
		max-width: 1120px;
		margin: 0 auto;
	}

	.program-desktop .about--header {
		margin: 35px 0 0 20px;
	}

	.program-desktop table {
		border-spacing: 20px;
		max-width: 1120px;
	}

	.program-desktop td {
		width: 100px;
		color: var(--text-gray);
		font-size: 15px;
		border-radius: 7px;
	}

	.table-headers td {
		color: white;
		padding: 15px;
		background: #dcdcdc;
		font-weight: 700;
	}

	.table-left {
		background: #6d70c0;
		padding: 15px;
		color: white !important;
		font-weight: 700;
	}

	.table-small {
		font-size: 13px !important;
	}

	.participation {
		margin: 60px auto 80px auto;
		max-width: 1080px;
	}

	.participation p {
		font-size: 15px;
	}

	/* science */
	.science-background a,
	.back-to-main {
		font-size: 22px;
		top: 40px;
		left: 60px;
		font-weight: bold;
	}

	.science-background {
		height: 660px;
	}

	.science-background h2 {
		font-size: 67px;
	}

	.science-content,
	.science-register {
		max-width: 1230px;
		margin: 0 auto;
	}

	.science-separator {
		max-width: 1294px;
		margin: 100px auto 0 auto;
		border-radius: 5px;
	}

	.science-content h3,
	.science-register h3 {
		font-size: 28px;
		padding: 0;
		margin: 60px 0;
	}

	.section-img {
		max-width: 100%;
	}

	.science-physics,
	.science-maths,
	.science-programming {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 40px;
	}

	.science-physics.section-1 .section:nth-of-type(1),
	.science-maths .section:nth-of-type(1) {
		order: 1;
		grid-column: 1 / 3;
	}

	.science-physics.section-1 .section:nth-of-type(2),
	.science-maths .section:nth-of-type(2) {
		order: 2;
		grid-column: 3 / 6;
	}

	.science-physics.section-1 .section:nth-of-type(3),
	.science-maths .section:nth-of-type(3) {
		order: 4;
		grid-column: 4 / 6;
	}

	.science-physics.section-1 .section:nth-of-type(4),
	.science-maths .section:nth-of-type(4) {
		order: 3;
		grid-column: 1 / 4;
	}

	.science-physics.section-1 .section:nth-of-type(5) {
		order: 6;
		grid-column: 4 / 6;
	}

	.science-physics.section-1 .section:nth-of-type(6),
	.science-maths .section:nth-of-type(5) {
		position: relative;
		order: 5;
		grid-column: 1 / 4;
	}

	.science-physics.section-1 .section:nth-of-type(6)::before,
	.science-physics.section-2 .section:nth-of-type(4)::before,
	.science-physics.section-4 .section:nth-of-type(4)::before {
		content: '';
		position: absolute;
		width: 2px;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
		right: -30px;
	}

	.science-text-bigger {
		line-height: 18px;
		font-size: 19px;
	}

	.science-physics .section:nth-of-type(1),
	.science-programming.section-2 .section:nth-of-type(1) {
		order: 2;
		grid-column: 4/ 6;
	}

	.science-physics .section:nth-of-type(2),
	.science-programming.section-2 .section:nth-of-type(2) {
		order: 1;
		grid-column: 1 / 4;
	}

	.science-physics .section:nth-of-type(3) {
		order: 4;
		grid-column: 4 / 6;
	}

	.science-physics .section:nth-of-type(4),
	.science-physics.section-4 .section:nth-of-type(3),
	.science-programming.section-2 .section:nth-of-type(3) {
		position: relative;
		order: 3;
		grid-column: 1 / 4;
		width: 100%;
		justify-self: center;
	}

	.science-physics.section-1 .section ul,
	.science-physics.section-2 .section:nth-of-type(3) ul {
		padding: 0 20px 0 56px;
	}

	.science-physics.section-1 .section li,
	.science-physics.section-2 .section:nth-of-type(3) li {
		margin: 0 0 20px 0;
		font-size: 19px;
	}

	.science-physics.section-2 .section:nth-of-type(1) ul {
		padding: 0 20px 20px 20px;
	}

	.science-programming.section-1 .section {
		grid-column: 1 /4;
	}

	.science-programming.section-1 .section-img {
		grid-column: 4/6;
	}

	.science-text-desktop {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0 30px;
		background: var(--science-physics);
		line-height: 23px;
		border-radius: 10px;
		text-align: center;
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	.science-text-desktop .science-color-physics-bigger {
		color: white !important;
		padding: 25px;
	}

	.science-lecturers h3 {
		font-size: 28px;
		margin: 60px 0;
	}

	.lecturers-gif {
		margin: 0 auto;
		max-width: 1040px;
	}

	.science-lecturers .science-text {
		font-size: 23px;
		max-width: 850px;
		margin: 0 auto 60px auto;
		line-height: 31px;
	}

	.lecturers-container {
		flex-direction: row;
		justify-content: space-between;
		max-width: 675px;
		margin: 0 auto 70px auto;
		flex-wrap: wrap;
	}

	.text-small {
		font-size: 17px;
	}

	.lecturers-mission .science-text:first-of-type {
		max-width: 680px;
	}

	.science-price-list {
		grid-template-columns: 800px 209px;
		max-width: 1040px;
		margin: 0 auto;
		gap: 20px 48px;
	}

	.science-price-list h5 {
		justify-content: unset;
		padding: 18px 0 18px 18px;
	}

	.science-price-list p {
		font-size: 17px !important;
	}

	.lecturers-where {
		max-width: 731px;
		height: 202px;
		margin: 80px auto;
	}

	.lecturers-programming .lecturers-where {
		height: 214px;
	}

	.lecturers-where .science-text {
		font-size: 19px;
		margin: 0 0 15px 0;
	}

	.science-classes div {
		margin: 0 5px;
	}

	.register-form {
		margin: 0 auto 140px auto;
	}

	.register-form input,
	.register-form select {
		font-size: 17px;
	}

	.frame-forMinors {
		padding: 0 10px 0 30px !important;
		margin: 20px 0 0 0;
	}

	.forMinors-tutors {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}

	.forMinors-slider {
		margin: 25px 20px 0 0;
	}

	.nextNav,
	.prevNav {
		width: 40px;
	}

	.forMinors-tutors .about--header {
		grid-column: 4/6;
		grid-row: 1;
		margin: 0 0 0 10px;
	}

	.forMinors-tutors .about--text {
		grid-column: 4/6;
		padding: 0 20px 0 10px;
	}

	.forMinors-logo {
		margin: 60px auto;
	}

	.about-pasco {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 32px;
	}

	.about-pasco ul {
		padding: 0 0 0 20px;
		text-align: left;
	}

	.about-pasco .about--header:nth-of-type(1) {
		grid-column: 1;
		grid-row: 1;
	}

	.about-pasco .about--header:nth-of-type(2) {
		grid-column: 2;
		grid-row: 1;
	}

	.about-pasco .about--text:nth-of-type(1) {
		grid-column: 1;
		grid-row: 2;
	}

	.about-pasco .about--text:nth-of-type(2) {
		grid-column: 2;
		grid-row: 2;
	}

	.about-pasco .about--text:nth-of-type(3) {
		grid-column: 3;
		grid-row: 2;
	}

	.pasco-slider {
		margin: 25px auto 60px auto;
	}

	.about--logo {
		grid-column: 3 !important;
		grid-row: 2;
	}

	.pasco-logo {
		margin: 50px auto 20px auto;
		max-width: 270px;
	}

	.downloads__main,
	.form__main {
		max-width: 80%;
		margin: 20px auto;
	}

	.footer-copyright p {
		font-size: 15px;
		margin: 0 150px 0 0;
	}
}

@media (min-width: 1295px) {
	.main section{
		gap: 48px;
	}

	.main_tile img {
		max-width: 200px;
	}

	.science-text {
		padding: 0;
	}

	.section-img {
		padding: 0;
	}

	.forMinors-tutors .about--text {
		padding: 0 10px;
	}

	.hero-science--header,
	.hero-science--p {
		padding-right: 0px;
	}

	.science-classes div {
		margin: 0;
	}

	.science-physics.section-1 .section ul,
	.science-physics.section-2 .section:nth-of-type(3) ul {
		padding: 0px 20px;
	}

	.science-physics.section-2 .section:nth-of-type(1) ul {
		padding: 20px 0;
	}

	.science-physics ul {
		margin: 0 0 0 20px;
	}
}

@media (min-width: 1450px) {
	nav {
		justify-content: center;
	}


	.main_container-overlay img {
		top: -10%;
	}

	.hero-text {
		width: unset;
		grid-template-columns: 670px 550px;
		padding: 520px 20px 260px 20px;
	}

	.hero-header {
		font-size: 40px;
	}

	.hero-p {
		font-size: 17px;
	}

	.hero-p:nth-of-type(2) {
		margin: -30px 0px 0 0;
	}
}
