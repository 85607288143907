:root {
    --textColor: #252b21;
}

.training {
    font-family: var(--jakarta), sans-serif;
}

.training_container {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    max-width: 1120px;
    margin: 0 auto;
    box-sizing: content-box;
}

.training h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 800;
}

.training p, .training_leader-text span {
    font-size: 14px;
    margin: 0;
}

.training img {
    width: 100%;
}

.training_banner {
    height: 150px;
    display: flex;
    background-color: #ebf3e6;
}

.training_banner img {
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}

/* intro */
.training_intro {
    margin-top: 16px;
}

.training_intro section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.training_intro-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.training_intro-text div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.training_intro-quote {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
}

.training_intro-quote span {
    display: flex;
    justify-content: right;
    opacity: 0.6;
    padding-top: 18px;
    font-size: 14px;
}

.training_intro-quote p, .training_motivation-quote p, .training_pricing-quote p {
    display: flex;
    flex-direction: column;
    background-color: #ebf3e6;
    border-radius: 10px;
    padding: 18px 20px;
    line-height: 24px;
    font-weight: 500;
    font-style: italic;
    max-width: 560px;
    box-sizing: border-box;
}

.training_divider {
    width: 100%;
    height: 1px;
    background-color: #252B21;
    opacity: 0.6;
    margin: 32px 0;
}

/* modules */

.training_modules,
.training_modules-cards-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.training_module-card {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 240px;
    border-radius: 10px;
}

.training_module-card-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.training_module-card p {
    color: var(--textColor);
    opacity: 0.6;
    font-weight: 500;
    line-height: 24px;
}

.training_module-card h3 {
    line-height: 32px;
}

.training_module-card span {
    display: flex;
    justify-content: end;
    color: var(--textColor);
    font-weight: 800;
    font-size: 60px;
}

.training_modules-container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 24px;
}

.training_module {
    position: relative;
    cursor: pointer;
}

.training_module-title {
    color: white;
    background: #9ac181;
    padding: 16px 24px;
    border-radius: 8px;
}

.training_module-expanded__text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;
    margin: 0 !important;
    background-color: white;
    color: var(--textColor);
    line-height: 24px;
}

.training_module-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    padding: 0 12px;
    max-width: 720px;
}

.training_module-text p {
    font-weight: 500;
}

.training_module-text h3 {
    line-height: 32px;
}

.training_module ul {
    list-style: initial;
    padding-left: 24px;
}

.training_module-quote {
    background-color: #ebf3e6;
    border-radius: 10px;
    padding: 18px 20px;
    line-height: 24px;
    font-weight: 500;
    font-style: italic;
}

.training_module .expanded__cross {
    width: initial;
    height: initial;
}

.training_module.active .expanded__cross::before,
.training_module.active .expanded__cross::after {
    background: white !important;
}


/* motivation */

.training_motivation-section-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    line-height: 24px;
}

.training_motivation {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.training_motivation section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.training_motivation-quote p {
    display: flex;
    flex-direction: column;
    background-color: #ebf3e6;
    border-radius: 10px;
    padding: 18px 20px;
    line-height: 24px;
    font-weight: 500;
    font-style: italic;
    max-width: 560px;
    box-sizing: border-box;
}

/* leaders */

.training_leaders, .training_leader {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.training_leader section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.training_leader-text {
    line-height: 24px;
}

.training_leader-text span {
    margin-bottom: 8px;
}

.training_leader-text h3 {
    margin-bottom: 16px;
}

.training_leader-links {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin-top: 24px;
}

/* pricing */
.training_pricing section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}

.training_pricing-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    line-height: 24px;
}

.training_additional-info {
    color: var(--textColor);
    opacity: 0.6;
    font-weight: 500;
    padding-bottom: 90px;
}

@media (min-width: 768px) {
    .training_container {
        margin-top: 32px;
        padding: 0 24px;
    }

    .training_banner {
        height: auto;
    }

    .training h3 {
        font-size: 20px;
    }

    /* intro */
    .training_intro {
        margin-top: 32px;
    }

    .training_intro section {
        flex-direction: row;
        gap: 40px;
    }

    .training_intro-text {
        justify-content: center;
    }

    /* modules */
    .training_modules-cards-container {
        flex-direction: row;
        margin: 0 auto;
    }

    .training_modules-container {
        margin-top: 48px;
        gap: 32px;
    }

    .training_module-card {
        flex: 1;
        max-width: 360px;
    }

    .training_module-expanded__text {
        flex-direction: row;
        padding: 0 12px;
        margin-top: 32px !important;
    }

    .training_module-text {
        padding: 0;
        margin: 0;
    }

    .training_module-quote {
        height: fit-content;
    }

    .training_modules .training_divider {
        margin-top: 24px;
    }

    /* motivation */
    .training_motivation {
        gap: 48px;
    }

    .training_motivation-section-text {
        gap: 24px;
    }

    /* leaders */
    .training_leader {
        gap: 48px;
    }

    .training_leader-text h3 {
        margin-bottom: 24px;
    }

    .training_leader-links {
        flex-direction: row;
        align-items: initial;
        margin-top: 40px;
    }

}

@media (min-width: 850px) {
    /* motivation */
    .training_motivation section, .training_leader section {
        flex-direction: row;
    }

    /* pricing */
    .training_pricing section {
        flex-direction: row;
    }
}


@media (min-width: 1080px) {
    .training h3 {
        font-size: 24px;
    }

    .training p, .training_leader-text span {
        font-size: 16px;
    }

    .training_divider {
        margin: 96px 0;
    }

    /* intro */
    .training_intro {
        margin-top: 96px;
    }

    .training_intro-quote span {
        font-size: 16px;
    }

    /* modules */
    .training_modules-container {
        margin-top: 96px;
    }

    .training_modules {
        gap: 24px;
    }

    .training_module-card {
        min-height: 470px;
        padding: 36px 40px;
    }

    .training_module-card span {
        font-size: 96px;
    }

    .training_module-quote, .training_intro-quote p, .training_motivation-quote p, .training_pricing-quote p {
        padding: 36px 40px;
    }

    .training_module-quote p{
        min-width: 376px;
    }

    .training_intro-quote p, .training_motivation-quote p, .training_pricing-quote p {
        width: 560px;
    }


    .training_motivation section, .training_leader section {
        gap: 40px;
    }

    /* leaders */
    .training_leaders .training_divider, .training_intro .training_divider {
        margin: 48px 0;
    }

    /* pricing */
    .training_pricing + .training_divider {
        margin-bottom: 48px;
    }
}

@media (min-width: 1440px) {
    .training img {
        width: auto !important;
    }
}
